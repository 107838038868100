import React, { useState } from "react";
import InputBox from "../../Components/InputBox/InputBox";
import NativeSelect from "../../Components/NativeSelect/NativeSelect";
import Button from "../../Components/Button/Button";
import "./RaiseComplaint.css";
import Select from "../../Components/Select/Select";
import Header from "../../Components/Header/Header";
import {
  AddIcon,
  ArrowIcon,
  CloseIcon,
  MicIcon,
  OngoingCallIcon,
  PreviousCallIcon,
  StopIcon,
} from "../../Assets/assets";
import { createComplaint } from "../../Redux/complaints/action";
import { connect } from "react-redux";
import { setErrorStatus } from "../../Redux/status/action";
import { dateAndTimeConverter } from "../../Utils/constants";
import { useEffect } from "react";
import { useRef } from "react";
import {
  isValidIndianPhoneNumber,
  isValidArray,
  isValidObject,
} from "../../Services/validators";
import AudioPlayer from "../../Components/AudioPlayer/AudioPlayer";
import useVoiceRecorder from "../../Services/useVoiceRecorder";
import { voiceRecorderTimeLimit } from "../../Services/config";
import Modal from "../../Components/Modal/Modal";
import CropperModal from "../../Components/CropperModal/CropperModal";

function RaiseComplaint(props) {
  const [voiceRecorderControl, setVoiceRecorderControl] = useState(null);
  const { startRecording, saveRecording } = useVoiceRecorder();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [file, setFile] = useState({
    image: [],
    audio: null,
  });
  const [croppedImage, setCroppedImage] = useState([]);
  const [running, setRunning] = useState(false);
  const [counter, setCounter] = useState(null);
  const [recordedAudio, setRecordedAudio] = useState({
    audioBlob: false,
    audioURL: "",
  });

  useEffect(() => {
    if (recordedAudio?.audioURL) {
      setVoiceRecorderControl(null);
    }
  }, [recordedAudio?.audioURL]);

  const voiceRecorderHandler = async () => {
    if (voiceRecorderControl === null || voiceRecorderControl === "cancel") {
      setVoiceRecorderControl("start");
      setCounter(0);
      setRunning(true);

      const res = await startRecording();
      if (res === false) {
        /**
         * voice recorder returns false is microphone is detected or any other issues in recording
         */
        setVoiceRecorderControl(null);
      }
    } else if (voiceRecorderControl === "start") {
      setVoiceRecorderControl("stop");
      setCounter(null);
      setRunning(false);
      const resBlob = await saveRecording();

      setRecordedAudio({
        audioBlob: resBlob,
        audioURL: URL.createObjectURL(resBlob),
      });

      const audioFile = new File([resBlob], `${+new Date()}.mp3`, {
        type: "audio/mp3",
      });

      setFile({
        ...file,
        audio: audioFile,
      });
    } else if (voiceRecorderControl === "stop") {
      setVoiceRecorderControl("cancel");
      setRecordedAudio({
        audioBlob: false,
        audioURL: "",
      });
    }
  };

  useEffect(() => {
    let counterTimer;
    if (
      counter !== null &&
      typeof counter === "number" &&
      counter <= voiceRecorderTimeLimit &&
      running
    ) {
      counterTimer = setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
    } else {
      return () => clearTimeout(counterTimer);
    }
    if (counter === voiceRecorderTimeLimit) {
      setVoiceRecorderControl("stop");
      voiceRecorderHandler();
    }
    // eslint-disable-next-line
  }, [counter]);

  const handleDisable = () => {
    if (
      props.formData.issue.length === 0 ||
      props.formData.pctNo.length === 0 ||
      (props.formData.callId.length === 0 &&
        props.formData.sourceType === "ExoTel Call") ||
      (props.formData.sourceType !== "ExoTel Call" &&
        props.formData.phoneNumber.length !== 0 &&
        isValidPhoneNumber === false)
    ) {
      return true;
    }

    if (props.formData.sourceType === "others") {
      if (
        props.formData.issue.length === 0 ||
        props.formData.pctNo.length === 0 ||
        props.formData.phoneNumber.length === 0 ||
        croppedImage.length === 0 ||
        file.audio.length === 0
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className="inherit-parent-width inherit-parent-height flex-center-children-horizontally">
      <div className="max-width-767px inherit-parent-width inherit-parent-height">
        <Header
          title="Customer Support"
          backOnClick={() => {
            props.navigate("/complaint");
            props.setFormData({
              name: "",
              issue: [],
              pctNo: "",
              phoneNumber: "",
              callId: "",
            });
          }}
          logoutOnClick={props.logoutClick}
        />
        <div className="padding-larger parent-height-sub-64px">
          {isValidObject(props.locations.data) ? (
            <form
              className="inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between"
              onChange={(event) => {
                props.setFormData({
                  ...props.formData,
                  [event.target.name]: event.target.value,
                });
              }}
              onSubmit={(event) => {
                event.preventDefault();
                const data = {
                  name: props.formData.name,
                  issue: props.formData.issue,
                  pctNo: String(
                    props.formData.pctNo?.[0]?.split("-")?.[0]
                  ).trim(),
                  phoneNumber: props.formData.phoneNumber,
                  callId: props.formData.callId,
                  sourceType: props.formData.sourceType,
                  mediaData: file.audio
                    ? [...croppedImage, file.audio]
                    : croppedImage,
                };
                props.createComplaint(data);
                props.setFormData({
                  name: "",
                  issue: [],
                  pctNo: [],
                  phoneNumber: "+91",
                  callId: "",
                  sourceType: "",
                });
              }}
            >
              <div>
                <div className="font-size-medium text-align-center ">
                  RAISE COMPLAINT
                </div>
                <div className="margin-top-default">
                  <NativeSelect
                    name="sourceType"
                    removeResponsive={true}
                    label="SOURCE TYPE"
                    selectClassName="text-transform-capitalize"
                    options={["", "ExoTel Call", "Zadarma Call", "Others"]}
                    defaultValue={props.formData.sourceType}
                  />
                  <InputBox
                    name="name"
                    type="text"
                    removeResponsive={true}
                    label="NAME"
                    value={props.formData.name}
                  />
                  <Select
                    chips={props.formData?.issue}
                    clearChips={() => {
                      props.setFormData({
                        ...props.formData,
                        issue: [],
                      });
                    }}
                    modalTitle="SELECT ISSUE"
                    removeResponsive={true}
                    multiSelect={true}
                    name="category"
                    label="issue"
                    onChipAdded={(data) => {
                      props.setFormData({
                        ...props.formData,
                        issue: [...data],
                      });
                    }}
                    data-cy="category-chip-input-box"
                    options={[
                      "Restroom is not open",
                      "No water supply",
                      "Lights not working",
                      "Waterlogged in the drainage",
                      "Broken doors, latches",
                      "Lack of security measures",
                      "Janitors are not on duty",
                      "Unsanitary restroom",
                      "Fees are charged",
                      "Unhygienic environment",
                    ]}
                  />

                  <div className="padding-top-default">
                    <Select
                      chips={props.formData?.pctNo}
                      clearChips={() => {
                        props.setFormData({
                          ...props.formData,
                          pctNo: "",
                        });
                      }}
                      modalTitle="SELECT PCT NO"
                      removeResponsive={true}
                      name="pctNo"
                      removeChip={true}
                      label="pct No"
                      onChipAdded={(data) => {
                        props.setFormData({
                          ...props.formData,
                          pctNo: data,
                        });
                      }}
                      multiSelect={false}
                      data-cy="pctNo-chip-input-box"
                      options={Object.values(props.locations.data)?.sort(
                        (a, b) =>
                          a.PCTNo?.localeCompare(b.PCTNo, undefined, {
                            numeric: true,
                          })
                      )}
                    />
                  </div>

                  {props.formData.sourceType === "ExoTel Call" && (
                    <div className="padding-top-default">
                      <Accordion
                        title="Call logs"
                        description="Select contact to raise complaint"
                        formData={props.formData}
                        data={
                          props.calls?.data && Object.values(props.calls?.data)
                        }
                        setFormData={props.setFormData}
                      />
                    </div>
                  )}
                  {props.formData.sourceType !== "ExoTel Call" && (
                    <InputBox
                      className={`inherit-parent-width border-radius-default margin-top-default ${
                        props.formData.sourceType === "Others"
                          ? ""
                          : "margin-bottom-large"
                      }`}
                      name="phoneNumber"
                      label="Phone Number"
                      type="tel"
                      value={props.formData.phoneNumber}
                      removeResponsive={true}
                      validation={() => {
                        if (
                          !isValidIndianPhoneNumber(
                            props.formData.phoneNumber
                          ) &&
                          props.formData.phoneNumber !== "+91"
                        ) {
                          setIsValidPhoneNumber(false);
                          return {
                            status: false,
                            message: "Please enter a valid phone number",
                          };
                        } else {
                          setIsValidPhoneNumber(true);
                        }
                      }}
                    />
                  )}

                  {props.formData.sourceType === "Others" && (
                    <div>
                      <>
                        <div className="padding-bottom-default font-family-RHD-medium">
                          ADD IMAGES
                        </div>
                        <div className="display-flex">
                          {croppedImage?.map((data, index) => (
                            <div
                              key={index}
                              style={{ height: "56px" }}
                              className="margin-right-large position-relative"
                              data-cy={`uploaded-image-thumbnail-${index}`}
                            >
                              <div
                                onClick={() => {
                                  setCroppedImage(
                                    Object.values(croppedImage).filter(
                                      (value) => value.name !== data.name
                                    )
                                  );
                                }}
                                data-cy="remove-uploaded-image-button"
                                className="background-color-grey width-fit-content border-radius-100-percentage flex-align-items-center flex-justify-content-center position-absolute right-0 opacity-60-percent"
                              >
                                <CloseIcon />
                              </div>
                              <img
                                className="border-radius-default"
                                height={56}
                                width={56}
                                src={data && URL.createObjectURL(data)}
                                alt="data"
                              />
                            </div>
                          ))}

                          <form
                            className="inherit-parent-width"
                            onChange={(event) => {
                              if (event.target.files) {
                                if (
                                  [
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                  ].includes(event.target.files[0]?.type) ===
                                  false
                                ) {
                                  return setErrorStatus({
                                    code: "custom",
                                    message: "Kindly upload a valid File",
                                  });
                                }

                                if (
                                  [
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                  ].includes(event.target.files[0]?.type) ===
                                  true
                                ) {
                                  setFile({
                                    image: [
                                      ...file.image,
                                      URL.createObjectURL(
                                        event.target.files[0]
                                      ),
                                    ],
                                  });
                                }
                              }
                            }}
                          >
                            <div className="height-width-56px margin-bottom-default cursor-pointer">
                              <label>
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  name={
                                    props.inputName
                                      ? props.inputName
                                      : "fileUploaderInput"
                                  }
                                  onClick={(event) => {
                                    event.target.value = "";
                                  }}
                                  className="visibility-hidden position-absolute"
                                />

                                {(!isValidArray(croppedImage) ||
                                  (isValidArray(croppedImage) &&
                                    croppedImage.length <= 4)) && (
                                  <div
                                    className="background-color-secondary height-width-56px display-flex flex-justify-content-center flex-align-items-center border-radius-default padding-top"
                                    data-cy="file-upload-icon"
                                  >
                                    <AddIcon />
                                  </div>
                                )}
                              </label>
                            </div>
                          </form>
                        </div>
                      </>
                      <>
                        <div className="padding-top-small font-family-RHD-medium">
                          ADD VOICE NOTES
                        </div>

                        <div className="flex-justify-content-space-center margin-vertical-large flex-align-items-center">
                          {!recordedAudio?.audioURL && (
                            <div className="flex-justify-content-space-between inherit-parent-width flex-align-items-center">
                              <div className="display-flex flex-align-items-center">
                                <div
                                  className="flex-place-children-page-center  border-radius-50-percentage"
                                  onClick={() => voiceRecorderHandler()}
                                  data-cy={`${
                                    voiceRecorderControl === "start"
                                      ? "stop-record-button"
                                      : "start-record-button"
                                  }`}
                                >
                                  {voiceRecorderControl === "start" ? (
                                    <StopIcon />
                                  ) : (
                                    <MicIcon />
                                  )}
                                </div>
                                <div className="padding-left-large">
                                  {voiceRecorderControl === "start"
                                    ? "Recording..."
                                    : "Tap the mic to record audio"}
                                </div>
                              </div>
                              {voiceRecorderControl === "start" && (
                                <div style={{ width: 50 }}>
                                  00:
                                  {counter?.toString().length === 1 ? "0" : ""}
                                  {counter}
                                </div>
                              )}
                            </div>
                          )}
                          {recordedAudio?.audioURL && (
                            <AudioPlayer
                              className={"flex-basis-80-percentage"}
                              closeOnClick={() => {
                                setRecordedAudio({
                                  audioBlob: false,
                                  audioURL: "",
                                });
                                setFile({
                                  ...file,
                                  audio: null,
                                });
                              }}
                              src={
                                typeof recordedAudio.audioURL === "string"
                                  ? recordedAudio.audioURL
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="display-flex font-size-medium flex-justify-content-center">
                  Want to link with existing complaint?
                  <span
                    onClick={() => {
                      if (props.formData.phoneNumber) {
                        props.navigate("/linkComplaint");
                      } else {
                        setErrorStatus({
                          code: "custom",
                          message:
                            "Please use ExoTel source to enter name and select call logs",
                        });
                      }
                    }}
                    data-cy="link-complaint"
                    className="font-color-primary padding-left-small"
                  >
                    Link
                  </span>
                </div>
                <div className="flex-center-children-vertically margin-top-default margin-bottom-default">
                  <hr
                    style={{ width: "100%" }}
                    className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                  />
                  <span className=" margin-horizontal-default display-inline-block font-color-secondary font-size-small">
                    OR
                  </span>
                  <hr
                    style={{ width: "100%" }}
                    className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                  />
                </div>

                <Button
                  text="Create"
                  data-cy="create-button"
                  type="submit"
                  boxShadow={false}
                  loading={props.complaints.loading}
                  disabled={handleDisable()}
                />
              </div>
            </form>
          ) : (
            <div>
              <Suspense />
            </div>
          )}
        </div>
      </div>
      <Modal
        show={isValidArray(file?.image)}
        canIgnore={true}
        onClose={() => {
          setFile({ ...file, image: [] });
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="inherit-parent-height max-height-500px"
      >
        <div
          data-cy="cropper-modal"
          className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <CropperModal
            className="border-radius-default"
            OnBlobCreate={(blob) => {
              const imageFile = new File([blob], `${+new Date()}.png`, {
                type: "image/png",
              });
              setCroppedImage([...croppedImage, imageFile]);
            }}
            file={file.image}
            setFile={() => {
              setFile({ ...file, image: [] });
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    locations: state.locations,
    calls: state.calls,
  };
};

const mapDispatchToProps = function () {
  return { createComplaint: (data) => createComplaint(data) };
};

export default connect(mapStateToProps, mapDispatchToProps)(RaiseComplaint);

const Accordion = (props) => {
  const [show, setShow] = useState(true);
  const [count, setCount] = useState(0);
  const counterRef = useRef();

  useEffect(() => {
    let ongoingCall;
    props.data?.forEach((call) => {
      if (
        call.status === "Answered" &&
        (ongoingCall === undefined ||
          ongoingCall.lastUpdatedAt < call.lastUpdatedAt)
      ) {
        ongoingCall = call;
      }
    });
    let intervalId;
    if (ongoingCall) {
      intervalId = setInterval(() => {
        setCount(Math.floor((+new Date() - ongoingCall.lastUpdatedAt) / 1000));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props.data]);

  useEffect(() => {
    const minutes = Math.floor(count / 60);
    const seconds = count % 60;
    if (counterRef.current) {
      counterRef.current.textContent = formatTime(minutes, seconds);
    }
  }, [count, props.data]);

  return (
    <>
      <div
        className={`display-flex flex-justify-content-space-between`}
        onClick={() => {
          if (show === false) {
            setShow(true);
          } else {
            setShow(false);
          }
        }}
      >
        <div
          className={`${
            show ? "font-family-gilroy-medium" : ""
          } font-size-default`}
        >
          <div className="padding-bottom-default">{props.title}</div>
          <div className="font-size-small">{props.description}</div>
        </div>
        <div className="display-flex flex-align-items-center">
          <DropDownButton
            dropDownButton-data-cy="title-dropDown"
            dropDownClick={() => {
              if (show === false) {
                setShow(true);
              } else {
                setShow(false);
              }
            }}
            showData={show}
          />
        </div>
      </div>
      {show && (
        <div
          style={{ height: "146px", overflow: "scroll" }}
          className="padding-top-large"
        >
          {props.data
            .filter((call) => !isValidArray(call.connectedComplaints))
            ?.map((data, index) => (
              <div
                data-cy={`call-${data.documentId}`}
                className="display-flex flex-justify-content-space-between padding-bottom-large"
                key={index}
              >
                <div className="display-flex flex-justify-content-center flex-align-items-center">
                  <div style={{ height: "16px", width: "16px" }}>
                    {data.status === "ONGOING" ? (
                      <OngoingCallIcon />
                    ) : (
                      <PreviousCallIcon />
                    )}
                  </div>
                  <div className="display-flex padding-left-default flex-direction-column flex-justify-content-center">
                    <div className="padding-bottom-default">
                      {data.callFrom}
                    </div>
                    <div className="font-size-smaller">
                      {data.status !== "Answered" ? (
                        secondsToMinutes(data.duration)
                      ) : (
                        <div ref={counterRef} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="display-flex flex-justify-content-center flex-align-items-center">
                  <div className="padding-right-default">
                    {data.status === "Answered"
                      ? "ONGOING"
                      : dateAndTimeConverter(data.createdAt, "Time")}
                  </div>
                  <div
                    style={{ width: "16px", height: "16px" }}
                    className="cursor-pointer"
                    data-cy="select-radio-button"
                    onClick={() => {
                      if (
                        props.formData.phoneNumber &&
                        props.formData.phoneNumber === data.callFrom &&
                        props.formData.callId === data.documentId
                      ) {
                        props.setFormData({
                          ...props.formData,
                          phoneNumber: "",
                          callId: "",
                        });
                      } else {
                        props.setFormData({
                          ...props.formData,
                          phoneNumber: data.callFrom,
                          callId: data.documentId,
                        });
                      }
                    }}
                  >
                    {props.formData.phoneNumber === data.callFrom &&
                    props.formData.callId === data.documentId ? (
                      <CheckedRadio />
                    ) : (
                      <UnCheckedRadio />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

const DropDownButton = (props) => {
  return (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={props.dropDownClick}
      data-cy={props["dropDownButton-data-cy"]}
    >
      <div
        style={{ height: "16px", width: "16px" }}
        className={`${props.showData === true ? "rotate-270" : "rotate-90"}`}
      >
        <ArrowIcon />
      </div>
    </div>
  );
};

const UnCheckedRadio = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="6.5" stroke="#404040" />
    </svg>
  );
};

const CheckedRadio = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="6.5" fill="#2965DD" stroke="#2965DD" />
      <circle cx="8" cy="8" r="3" fill="white" />
    </svg>
  );
};

function formatTime(minutes, seconds) {
  return `${minutes.toString().padStart(2, "0")}m ${seconds
    .toString()
    .padStart(2, "0")}s`;
}

function secondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes.toString().padStart(2, "0")}m ${remainingSeconds
    .toString()
    .padStart(2, "0")}s`;
}

const Suspense = (props) => {
  return [...Array(5)].map((_data, index) => (
    <div className="padding-default" key={index}>
      <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
    </div>
  ));
};
