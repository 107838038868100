import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  ArrowIcon,
  FallbackClipArt,
  FilterIcon,
  LinkIcon,
  RotateClearIcon,
  TickIcon,
} from "../../Assets/assets";
import Header from "../../Components/Header/Header";
import InputBox from "../../Components/InputBox/InputBox";
import Modal from "../../Components/Modal/Modal";
import { isValidArray, isValidObject } from "../../Services/validators";
import "./Complaints.css";
function Complaints(props) {
  const [searchKey, setSearchKey] = useState(null);
  const [compliantData, setCompliantData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedKey, setSelectedKey] = useState("status");
  const [selectedFilter, setSelectedFilter] = useState({
    status: [],
    category: [],
    dateRange: {
      from: new Date().toISOString().split("T")[0],
      to: new Date().toISOString().split("T")[0],
    },
    issueRaised: [],
    issueTakenOver: [],
    sourceType: "",
  });
  const filterData = useMemo(() => {
    const complaintsData = isValidObject(props.complaints.data)
      ? Object.values(props.complaints.data)
      : [];

    return {
      status: {
        title: "Status",
        value: ["Open", "Closed", "Review"],
      },
      category: {
        title: "Category",
        value: getUniqueCategories(complaintsData, "category"),
      },
      // Uncomment and customize additional fields if needed
      // dateRange: {
      //   title: "Date Range",
      //   value: {
      //     from: new Date().toISOString().split("T")[0],
      //     to: new Date().toISOString().split("T")[0],
      //   },
      // },
      // issueRaised: {
      //   title: "Issue Raised",
      // },
      // issueTakenBy: {
      //   title: "Issue Taken By"
      // },
    };
  }, [props.complaints.data]);

  useEffect(() => {
    if (searchKey && searchKey?.length > 3) {
      if (props.complaints.data && isValidObject(props.complaints.data)) {
        setCompliantData(
          Object.values(props.complaints.data)?.filter((d) =>
            d.documentId
              ?.toString()
              .toLowerCase()
              .includes(searchKey.toString().trim().toLowerCase())
          )
        );
      }
    } else {
      if (props.complaints.data && isValidObject(props.complaints.data)) {
        setCompliantData(Object.values(props.complaints.data));
      }
    }
  }, [searchKey, props.complaints.data]);

  const isFilterApplied = (selectedFilter) => {
    if (
      selectedFilter.status?.length > 0 ||
      selectedFilter.category?.length > 0 ||
      selectedFilter.dateRange?.from !==
        new Date().toISOString().split("T")[0] ||
      selectedFilter.dateRange?.to !== new Date().toISOString().split("T")[0] ||
      selectedFilter.issueRaised?.length > 0 ||
      selectedFilter.issueTakenBy?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.complaints.data && isValidObject(props.complaints.data)) {
      let updatedData;

      const filterData = [...Object.values(props.complaints.data)];

      if (isFilterApplied(selectedFilter)) {
        updatedData = filterData?.filter((item) => {
          if (
            selectedFilter.status?.length > 0 &&
            !selectedFilter.status.includes(
              getStatus(item.status?.currentStatus)
            )
          ) {
            return false;
          }

          // Category filter
          if (
            selectedFilter.category?.length > 0 &&
            !selectedFilter.category.includes(item.category)
          ) {
            return false;
          }

          // Date range filter

          if (
            selectedFilter.dateRange?.from !==
              new Date().toISOString().split("T")[0] ||
            selectedFilter.dateRange?.to !==
              new Date().toISOString().split("T")[0]
          ) {
            const fromDate = +new Date(selectedFilter.dateRange.from);
            const toDate = +new Date(selectedFilter.dateRange.to);
            const itemDate = new Date(item.createdAt).setHours(0, 0, 0, 0);

            if (
              !(
                +new Date(itemDate) >= fromDate && +new Date(itemDate) <= toDate
              )
            ) {
              return false;
            }
          }

          // Check issueRaised filter
          if (selectedFilter.issueRaised?.length > 0) {
            let matchFound = false;
            selectedFilter.issueRaised.forEach((issue) => {
              if (issue.employeeId === item.issuedBy?.userDetails?.employeeId) {
                matchFound = true;
              }
            });
            if (!matchFound) {
              return false;
            }
          }

          if (selectedFilter.issueTakenBy?.length > 0) {
            let matchFound = false;
            selectedFilter.issueTakenBy.forEach((takenBy) => {
              if (takenBy.employeeId === item?.takenOverBy?.employeeId) {
                matchFound = true;
              }
            });
            if (!matchFound) {
              return false;
            }
          }

          return true;
        });
      } else {
        updatedData = filterData;
      }
      setCompliantData(updatedData);
    }
  }, [props.complaints.data, selectedFilter]);

  return (
    <div className="inherit-parent-height">
      <Header
        title={
          window.location.pathname.includes("linkComplaint")
            ? "Link to existing complaints"
            : "Customer Support"
        }
        backOnClick={
          window.location.pathname.includes("linkComplaint")
            ? () => {
                props.navigate(-1);
              }
            : false
        }
        logoutOnClick={props.logoutClick}
      />
      <div className="display-flex flex-justify-content-center flex-align-items-center padding-top-large padding-right-large padding-left-large">
        <InputBox
          name="searchKey"
          type="text"
          removeResponsive={true}
          label="COMPLAINT ID"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          value={searchKey}
        />
        <div
          data-cy="compliant-filter-icon"
          className="position-relative cursor-pointer"
          onClick={() => {
            setShowFilter(true);
          }}
        >
          <FilterIcon />
          {isFilterApplied(selectedFilter) && (
            <div
              style={{ height: "8px", width: "8px", top: "-2px", right: "0px" }}
              className="background-color-primary border-radius-16px position-absolute"
            />
          )}
        </div>
      </div>

      <div
        className={`inherit-parent-width ${
          window.location.pathname.includes("linkComplaint")
            ? "remaining-body-height-with-search"
            : "remaining-body-height_-128px"
        } flex-justify-content-center`}
      >
        <div className="inherit-parent-height inherit-parent-width max-width-767px overflow-x-scroll hide-scrollbar flex-justify-content-center">
          {!props.complaints.loading && isValidArray(compliantData) && (
            <div className="inherit-parent-width">
              <div
                className="padding-large inherit-parent-width"
                data-cy="complaints-list"
              >
                {compliantData
                  ?.sort((first, second) => {
                    return second.createdAt - first.createdAt;
                  })
                  .filter((data) => data?.currentStatus !== "UNDER REVIEW")
                  ?.map((data, index) => (
                    <div className="inherit-parent-width" key={index}>
                      <Card
                        onClick={() => {
                          props.navigate(`/complaint/${data.documentId}`);
                        }}
                        index={index}
                        data={data}
                        location={props.locations.data}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {!props.complaints.loading && !isValidArray(compliantData) && (
            <NoComplaintsFallback />
          )}

          {props.complaints.loading && <Suspense />}
        </div>
      </div>

      {!window.location.pathname.includes("linkComplaint") && (
        <>
          <div className="flex-center-children-vertically margin-large">
            <hr
              style={{ width: "100%" }}
              className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
            />
            <span className=" margin-horizontal-default display-inline-block font-color-secondary font-size-small">
              OR
            </span>
            <hr
              style={{ width: "100%" }}
              className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
            />
          </div>

          <div className="display-flex flex-justify-content-center padding-bottom-large">
            <div>Want to create a new complaint ? </div>
            <div
              data-cy="create-compliant-button"
              className="font-color-primary padding-left-default"
              onClick={() => {
                props.navigate("/raiseComplaint");
              }}
            >
              Create
            </div>
          </div>
        </>
      )}

      <Modal
        onClose={() => {
          setShowFilter(!showFilter);
        }}
        canIgnore={true}
        show={showFilter}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        maxWidth="none"
        width="inherit-parent-width"
        background="background-color-white"
        height="height-40-percentage"
        data-cy="requests-filter-modal"
      >
        <div
          data-cy="filter-modal"
          className="background-white padding-top-default padding-bottom-default padding-right-default font-family-gilroy-medium user-select-none inherit-parent-height "
        >
          <div className="flex-justify-content-space-between flex-align-items-center font-size-medium font-color-secondary padding-left-medium padding-right-medium padding-top-default padding-bottom-default">
            <div className="display-flex">
              <div className="font-size-small">FILTER</div>
              <div
                style={{ width: "16px", height: "16px" }}
                className="margin-left-default"
              >
                <FilterIcon />
              </div>
            </div>
            <div className="display-flex">
              <div
                className="cursor-pointer font-size-small"
                onClick={() => {
                  setSelectedFilter({
                    status: [],
                    category: [],
                    dateRange: {
                      from: new Date().toISOString().split("T")[0],
                      to: new Date().toISOString().split("T")[0],
                    },
                    issueRaised: [],
                    issueTakenOver: [],
                  });
                  setShowFilter(false);
                }}
                data-cy="clear-filter"
              >
                RESET
              </div>
              <div
                style={{ width: "16px", height: "16px" }}
                className="margin-left-default"
              >
                <RotateClearIcon />
              </div>
            </div>
          </div>
          <div className="display-flex font-size-medium font-family-gilroy-medium text-align-left inherit-parent-height">
            <div className="border-right-1px ">
              {Object.keys(filterData).map((data, index) => (
                <div
                  key={index}
                  className={`cursor-pointer width-max-content padding-top-default padding-bottom-default padding-left-medium padding-right-default  display-flex flex-align-items-center flex-justify-content-space-between ${
                    data === selectedKey ? "background-color-grey" : ""
                  }`}
                  onClick={() => {
                    setSelectedKey(data);
                  }}
                  data-cy={`compliant-filter-${data}`}
                >
                  <div> {filterData?.[data].title}</div>
                  {(
                    data === "dateRange"
                      ? selectedFilter?.[data]?.from !==
                          new Date().toISOString().split("T")[0] ||
                        selectedFilter?.[data]?.to !==
                          new Date().toISOString().split("T")[0]
                      : selectedFilter?.[data]?.length > 0
                  ) ? (
                    <div
                      style={{ width: "5px", height: "5px" }}
                      className="background-color-primary margin-left-default border-radius-100-percentage"
                    />
                  ) : (
                    <div
                      style={{ width: "5px", height: "5px" }}
                      className="background-color-white margin-left-default border-radius-100-percentage"
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="font-color-secondary padding-left-default inherit-parent-width overflow-x-scroll hide-scroll-bar">
              <div className="display-flex flex-direction-column inherit-parent-width">
                {filterData?.[selectedKey].value
                  ?.sort((a, b) => a.localeCompare(b))
                  .map((data, index) => (
                    <div className="display-flex flex-justify-content-space-between inherit-parent-width">
                      <div
                        data-cy={`compliant-filter-${selectedKey}-${data}`}
                        key={index}
                        className={`padding-default border-radius-default background-white inherit-parent-width`}
                        onClick={() => {
                          if (selectedFilter?.[selectedKey]?.includes(data)) {
                            setSelectedFilter({
                              ...selectedFilter,
                              [selectedKey]: selectedFilter?.[
                                selectedKey
                              ].filter((d) => d !== data),
                            });
                          } else {
                            setSelectedFilter({
                              ...selectedFilter,
                              [selectedKey]: [
                                ...selectedFilter?.[selectedKey],
                                data,
                              ],
                            });
                          }
                        }}
                      >
                        {data}
                      </div>
                      <div className="padding-small"></div>
                      <div className="display-flex flex-justify-content-center padding-right-default flex-align-items-center">
                        {selectedFilter?.[selectedKey]?.includes(data) ? (
                          <TickIcon />
                        ) : (
                          <TickIcon color="white" />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaints);

function getUniqueCategories(data, type) {
  if (isValidArray(data) && type) {
    const categories = data?.map((item) => item?.[type]);
    return Array.from(new Set(categories));
  }
}

const Card = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="display-flex inherit-parent-width flex-justify-content-space-between padding-bottom-medium padding-top-medium"
      data-cy={`complaint-${props.data.documentId}`}
    >
      <div className="parent-width_-68px display-flex flex-justify-content-space-between">
        <div className=" inherit-parent-width">
          <div className="font-size-medium font-family-RHD-medium padding-bottom-default inherit-parent-width text-overflow-ellipsis text-capitalize">
            {props.data.category?.toString()?.toLowerCase()}
          </div>

          <div className="font-size-small width-90-percentage text-overflow-ellipsis padding-bottom-default">
            {props.data.documentId}
          </div>

          <div className="font-size-small width-90-percentage text-overflow-ellipsis">
            {props.location?.[props.data.locationId]?.PCTNo} -{" "}
            {props.location?.[props.data.locationId]?.location}
          </div>
        </div>
      </div>
      <div className="display-flex flex-align-items-center">
        <LinkIcon />
        <div
          style={{ width: "16px", height: "12px" }}
          className="font-size-smaller display-flex flex-justify-content-center white-space-nowrap"
        >
          {props.data?.issuedBy?.linkedWith?.length}
        </div>

        <div className=" display-flex flex-align-items-center ">
          <ArrowIcon />
        </div>
      </div>
    </div>
  );
};

const NoComplaintsFallback = () => {
  return (
    <div
      className=" inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="no-complaints-fallback"
    >
      <FallbackClipArt />
      <div className=" padding-top-larger font-family-RHD-medium">
        No complaints yet
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        Please check after some time.
      </div>
    </div>
  );
};

const Suspense = () => {
  return (
    <div className=" inherit-parent-width padding-top-large">
      {[...Array(7)].map((_data, index) => (
        <div className=" padding-default inherit-parent-width" key={index}>
          <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
        </div>
      ))}
    </div>
  );
};

const getStatus = (data) => {
  if (data === "CLOSED") {
    return "Closed";
  }
  if (data === "OPEN") {
    return "Open";
  }
  if (data === "UNDER REVIEW") {
    return "Review";
  }
};
